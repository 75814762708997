:root {

  font-size: 14px;

  --bg-color: #212721;
  --bg-color--old: #363c42;
  --seconday-bg-color: #00c19f;
  --seconday-bg-color-old: #fca91c;

  --text-primary-color: #000;
  --text-secondary-color: #fff;


  --button-primary-color: #fff;
  --button-secondary-color: #fff;
  --button-danger-color: #fff;
  --button-primary-bg-color: #00c19f;
  --button-primary-bg-color--old: #fca91c;
  --button-secundary-bg-color: #363c42;
  --button-danger-bg-color: red;


  --button-risk_low-color: black;
  --button-risk_low-border-color: green;
  --button-risk_low-bg-color: #f7f7f7;


  --button-risk_medium-color: black;
  --button-risk_medium-border-color: #ffd507;
  --button-risk_medium-bg-color: #f7f7f7;

  --button-risk_high-color: black;
  --button-risk_high-border-color: rgb(219, 4, 4);
  --button-risk_high-bg-color: #f7f7f7;

  --button-loyalty_high-color: black;
  --button-loyalty_high-border-color: green;
  --button-loyalty_high-bg-color: #f7f7f7;


  --button-loyalty_medium-color: black;
  --button-loyalty_medium-border-color: #ffd507;
  --button-loyalty_medium-bg-color: #f7f7f7;

  --button-loyalty_low-color: black;
  --button-loyalty_low-border-color: rgb(219, 4, 4);
  --button-loyalty_low-bg-color: #f7f7f7;


}

.tab {
  margin-left: 1em;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  overflow: auto;
}

a {
  text-decoration: none;
  color: black;
}

.loginForm {
  max-width: 400px;
  border: 3px solid #f1f1f1;
  margin: 10px auto;
}

/*
input[type=text], input[type=password] , input[type=checkbox] , input[type=number], textarea, select {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
*/
textarea {
  height: 20em;
}

.textarea-6 {
  height: 6em;
}

/*
button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

*/
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

img.logo_login {
  width: 60%;
  margin-bottom: 2em;
  margin-top: 1em;
}



span.psw {
  float: right;
  padding-top: 16px;
}

.topBar {

  height: 4em;
  background-color: var(--seconday-bg-color);
  margin: 0;

}

@media (max-width: 768px) {
  .py-3 .topHeader {
    padding: 0 !important;
  }

}

.title {
  float: left;
  height: 2em;
  padding: 1em;
  /* color: white; */
}

.title a {
  color: white;
  text-decoration: none;
}

.topBar .alias,
.topBar .logout {
  float: right
}

.chart,
.appTable,
.standardSection {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.3em;
  margin: 0.4em;
}

.chart,
.appTable {
  width: 43%;
  height: 45%;
  float: left;

}

.chart .title,
.appTable .title,
.standardSection .title {

  border-color: #ccc;
  border-width: 2px;
  background-color: #cccccc78;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  padding-top: 0.5em;
  color: #333;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1em;
}

.chart .content {
  padding: 0.3em;
}

.chart,
.appTable,
.standardSection {
  overflow: auto;
  background-color: white;
}

.standardSection {
  margin: 0 auto;
  margin-top: 1em;
  padding: 0;
  max-width: 95%;
}

.standardSection .content {
  padding: 0.5em;
  padding-top: 3em;
}

.contractSection {
  max-width: 800px;
}

.appTable table,
.standardSection table {

  width: 100%;
}

.appTable tr:last-child td {
  border-bottom: 0;
}

.appTable th,
.appTable td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid rgb(204 194 194);
  ;
  border-right: 1px solid rgb(204 194 194);
  ;

}

.appTable th:last-child,
.appTable td:last-child {
  border-right: 0;
}


.pagination {
  padding: 0.5rem;
}

.number,
.percentage {
  text-align: right;
}

.hide {
  display: none;
}

.currency {
  text-align: right;
}

.integer {
  text-align: right;
}

.date {
  min-width: 6em;
}


.navbar-light .navbar-nav .nav-link {
  /*color: #212721;*/
  color: #051c2c;
}


.page-content {
  background-color: #eee;
}

.cancelButton {
  background-color: #f02727;
}

.half {
  width: 46%;
  float: left;
  margin: 2%;
}

.newElement {
  float: right;
  width: auto;
  background-color: #ec791d;
  margin-right: 1em;
}

.footer {
  position: fixed;
  bottom: 0;
  background-color: #333333;
  width: 100%;
  color: white;
  text-align: center;
}


.nav-link {
  color: #000;
  ;

}

.nav-link:hover {
  color: var(--button-primary-bg-color);

}

.newline {
  clear: both;
}

.margin-footer {
  margin-bottom: 25em;
}

.alertDIV {
  float: right;
}

.card {
  margin: 0 auto;
  overflow: auto;
}

.card-header {
  background-color: white;
  text-align: center;
  font-weight: bold;
}

.card-alert {
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}

.card-header-alert {
  background-color: white;
  text-align: center;
  font-weight: bold;
  position: -webkit-sticky;
  /* Soporte para Safari */
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.card-title-alert {
  flex-grow: 1;
  /* Permite que el título ocupe todo el espacio disponible */
  margin: 0;
}

.close-button-alert {
  position: absolute;
  right: 20px;
  /* Ajusta según el diseño */
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.card-body-alert {
  overflow-y: auto;
  flex-grow: 1;
  padding: 15px;
}

.card-header a {
  float: right;
}


#json_configuration-outer-box,
#json_configuration-container {
  width: 100% !important;
  height: 300px !important;
}

#json_configuration-container {
  border: 1px solid #ccc !important;
  border-radius: .25rem !important;
  height: 300px !important;
}

fieldset {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;
}

.loginPanel {
  background-color: var(--bg-color);
  color: #ccc;
  border-radius: 10px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  color: var(--button-secondary-color);
  background-color: var(--button-primary-bg-color);
  border-color: var(--button-primary-bg-color);
  max-width: 20em;
  float: right;
  margin-left: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
  color: var(--button-secondary-color);
  background-color: var(--button-secundary-bg-color);
  border-color: var(--button-secundary-bg-color);
  max-width: 20em;
  float: right;
  margin-left: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited,
.btn-danger:focus {
  color: var(--button-danger-color);
  background-color: var(--button-danger-bg-color);
  border-color: var(--button-danger-bg-color);
  max-width: 20em;
  float: right;
  margin-left: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.secondaryMenu {
  background-color: #ebebeb !important;
}

/* .form-control,
.form-control:visited,
.form-control:active,
.form-control:read-write {
  background-color: #ccc !important;
} */
main {
  padding: 1em;
  overflow: hidden;
}

.topHeader {
  min-width: 100% !important;
  padding: 0;
}

table {
  font-size: 0.9em;
}


.risk_low,
.risk_low:hover,
.risk_low:active,
.risk_low:visited,
.risk_low:focus {
  background-color: var(--button-risk_low-bg-color) !important;
  border-bottom: 6px solid var(--button-risk_low-border-color) !important;
  margin-bottom: 1em;
}

.risk_medium,
.risk_medium:hover,
.risk_medium:active,
.risk_medium:visited,
.risk_medium:focus {
  background-color: var(--button-risk_medium-bg-color) !important;
  border-bottom: 6px solid var(--button-risk_medium-border-color) !important;
  margin-bottom: 1em;
}

.risk_high,
.risk_high:hover,
.risk_high:active,
.risk_high:visited,
.risk_high:focus {
  background-color: var(--button-risk_high-bg-color) !important;
  border-bottom: 6px solid var(--button-risk_high-border-color) !important;
  margin-bottom: 1em;
}



.a_risk_low,
.a_risk_low:hover,
.a_risk_low:active,
.a_risk_low:visited,
.a_risk_low:focus,
.a_risk_low input {
  color: var(--button-risk_low-border-color) !important;
  -webkit-text-fill-color: var(--button-risk_low-border-color) !important;
  font-weight: bolder;
}

.a_risk_medium,
.a_risk_medium:hover,
.a_risk_medium:active,
.a_risk_medium:visited,
.a_risk_medium:focus,
.a_risk_medium input {
  color: var(--button-risk_medium-border-color) !important;
  -webkit-text-fill-color: var(--button-risk_medium-border-color) !important;
  font-weight: bolder;
}

.a_risk_high,
.a_risk_high:hover,
.a_risk_high:active,
.a_risk_high:visited,
.a_risk_high:focus,
.a_risk_high input {
  color: var(--button-risk_high-border-color) !important;
  -webkit-text-fill-color: var(--button-risk_high-border-color) !important;
  font-weight: bolder;
}


.loyalty_low,
.loyalty_low:hover,
.loyalty_low:active,
.loyalty_low:visited,
.loyalty_low:focus,
.loyalty_low input {
  background-color: var(--button-loyalty_low-bg-color) !important;
  border-top: 6px solid var(--button-loyalty_low-border-color) !important;
  -webkit-text-fill-color: var(--button-loyalty_low-border-color) !important;
  margin-top: 1em;
}

.loyalty_medium,
.loyalty_medium:hover,
.loyalty_medium:active,
.loyalty_medium:visited,
.loyalty_medium:focus,
.loyalty_medium input {
  background-color: var(--button-loyalty_medium-bg-color) !important;
  border-top: 6px solid var(--button-loyalty_medium-border-color) !important;
  -webkit-text-fill-color: var(--button-loyalty_medium-border-color) !important;
  margin-top: 1em;
}

.loyalty_high,
.loyalty_high:hover,
.loyalty_high:active,
.loyalty_high:visited,
.loyalty_high:focus,
.loyalty_high input {
  background-color: var(--button-loyalty_high-bg-color) !important;
  border-top: 6px solid var(--button-loyalty_high-border-color) !important;
  -webkit-text-fill-color: var(--button-loyalty_high-border-color) !important;
  margin-top: 1em;
}

.a_loyalty_low,
.a_loyalty_low:hover,
.a_loyalty_low:active,
.a_loyalty_low:visited,
.a_loyalty_low:focus,
.a_loyalty_low input {
  color: var(--button-loyalty_low-border-color) !important;
  -webkit-text-fill-color: var(--button-loyalty_low-border-color) !important;
  font-weight: bolder;
}

.a_loyalty_medium,
.a_loyalty_medium:hover,
.a_loyalty_medium:active,
.a_loyalty_medium:visited,
.a_loyalty_medium:focus,
.a_loyalty_medium input {
  color: var(--button-loyalty_medium-border-color) !important;
  -webkit-text-fill-color: var(--button-loyalty_medium-border-color) !important;
  font-weight: bolder;
}

.a_loyalty_high,
.a_loyalty_high:hover,
.a_loyalty_high:active,
.a_loyalty_high:visited,
.a_loyalty_high:focus,
.a_loyalty_high input {
  color: var(--button-loyalty_high-border-color) !important;
  -webkit-text-fill-color: var(--button-a_loyalty_high-border-color) !important;
  font-weight: bolder;
}


.mainNavBarButton {

  height: 0px !important;

}

.mainNavbarButton button span {
  background-color: #009688;
  border-radius: 2px;
}


.kpi-header {
  font-weight: bold;
  background-color: rgba(0, 0, 0, .03);
  text-align: justify;
}

.kpi {
  padding-right: 1em !important;
}

.kpi_main_value {
  font-size: 1.3em;
  font-weight: bold;
}

.kpi_up {
  color: green;
}


.kpi_down {
  color: red;
}

.kpi_up::after {
  font-family: "Font Awesome 5 Free";
  content: "\f062";
  display: inline-block;
  padding-left: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.kpi_down::after {
  font-family: "Font Awesome 5 Free";
  content: "\f063";
  display: inline-block;
  padding-left: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.companiesList div div:nth-child(2) {

  margin: -3px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.selectList {
  padding-top: 8px;
}

.selectList div div:nth-child(2) {

  margin: 2px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.DatetimeRangePicker1row .rdt {
  width: 50%;
  float: left;
}

.DatetimeRangePicker1row .rdt input {
  background-color: white !important;
}

.filter-button {
  float: right;
  max-width: 50px;
  padding: 0;
  margin: 0;
}

#filterPanel {
  display: none;
}

.comments {
  height: 6em;
}

.negative {
  color: red;

}

.filter_operator {
  width: 1em;
  float: left;
  margin-right: 1em;
  min-width: 5em;
}

.filter_value {
  width: auto !important;
  min-width: 7em;
}

.btn-back {
  background-color: white;
  color: black;
}

.lobbyMachinesContainer,
.lobbyPopupsContainer {
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 10px;
}


.lobbyMachine {
  border: 4px solid #e5e5e5;
  border-radius: 5px;
  margin: 2px;
  float: left;
  padding: 3px;
  width: 25vw;
  max-width: 230px;
  background-color: white;
}

.lobbyPromotion,
.lobbyTournament {
  border: 4px solid #e5e5e5;
  border-radius: 5px;
  margin: 2px;
  float: left;
  padding: 15px;
  width: 90%;
  background-color: white;
}

.lobbyPromotion .name,
.lobbyTournament .name {
  float: left;
}

.newLine {
  clear: both;
}

.lobbyMachine .name {
  font-weight: bold;
  height: 3em;
  user-select: none;
  float: left;
}

.lobbyMachine .type {
  float: right;
  user-select: none;
}

.lobbyMachine .provider {
  float: left;
  user-select: none;
}

.gridTitle {
  width: 100%;
}

.removeItem {
  float: right;
  padding-left: 24px;
}

.connected_icon_false {
  color: #808080 !important
}


.connected_icon_true {
  color: #3dcc0f !important;
}

.connected_icon_false,
.connected_icon_true {
  font-size: 0.7em;
}


.toolBarOption,
.toolBarOption:hover {
  padding: 1px;
  margin: 1px;
}

.toolBarUser,
.toolBarUser:hover,
.toolBarUser:visited,
.toolBarUser:active,
.toolBarUser:focus {
  background-color: transparent;
  border-color: grey;
}

.textarea-3 {
  height: 6em;
}

.summary label {
  font-weight: bold;
  margin-right: 1em;
}

.groups_list {
  background-color: #f8f9fa;
  border: 1px solid #6c757d;
  border-radius: 0px;
  min-height: 5em;
  list-style-type: none;
  padding-left: 0;
  cursor: pointer;
}

.btn-userInput {
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--button-primary-bg-color);
  border-color: var(--button-primary-bg-color);
  color: var(--button-secondary-color);
  text-align: center;

}

.machines_list {
  background-color: #f8f9fa;
  border: 1px solid #6c757d;
  border-radius: 5px;
  min-height: 5em;
  list-style-type: none;
  padding-left: 0;
  padding-right: 14px;
  cursor: pointer;
}

.group_item {
  text-align: center;
  margin: 7px;
  background-color: #e9ecef;
  padding: 4px;
  border: 1px solid #999999;
}




.tab-content {
  border-bottom: 1px solid rgb(222, 226, 230);
  ;
  border-left: 1px solid rgb(222, 226, 230);
  ;
  border-right: 1px solid rgb(222, 226, 230);
  ;
  padding: 10px;
}

/* CHAT */


.notification_status_CONNECTED {
  color: yellow;
}

.notification_status_BUSY {
  color: red;
}

.notification_status_AVAILABLE {
  color: #19f319;
}

.notification_status_DISCONNECTED {
  color: grey;
}

#connectionOptions {

  position: relative;
  background-color: white;
  width: fit-content;
  /*height: 200px;*/
  z-index: 1000;
  box-shadow: 3px 3px #212529;
  border: solid 1px grey;
  border-radius: 3px;
}

#connectionOptions {
  color: black;
  text-align: left;
  padding: 8px;
  top: 24px;
  min-width: 160px;
  position: relative;
}

.notificationWidget {
  text-align: left;
  padding-left: 4px;
  padding-top: 2px;
}

.notificationWidget a,
.notificationWidget .option {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.notificationWidget .option:hover {
  background-color: #fcaa1c69;
}


#chatWindow {
  background-color: var(--bg-color);
  z-index: 1000;
  position: absolute;
  top: 25%;
  left: 25%;

}

#chatToolbar {
  height: 45px;
}

#chatMessages {
  background-color: #dee2e6;
  margin: 10px;
  height: 400px;
  color: black;
  text-align: left;
  overflow-y: scroll;
  overflow-x: hidden;
}

#chatCommands {
  padding: 5px;
}

.callout {
  position: relative;
  margin: 18px 0;
  padding: 18px 20px;
  background-color: #eef4f9;
  /* easy rounded corners for modern browsers */
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.callout .notch {
  position: absolute;
  top: -10px;
  left: 20px;
  margin: 0;
  border-top: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #eef4f9;
  padding: 0;
  width: 0;
  height: 0;
  /* ie6 height fix */
  font-size: 0;
  line-height: 0;
  /* ie6 transparent fix */
  _border-right-color: pink;
  _border-left-color: pink;
  _filter: chroma(color=pink);
}

.border-callout {
  border: 1px solid #c5d9e8;
  padding: 10px 10px;
}

.border-callout .border-notch {
  border-bottom-color: #c5d9e8;
  top: -11px;
}


.border-callout .border-notch-left {
  border-right-color: #c5d9e8;
  top: -11px;
}

.callout-left {
  margin: 18px 22px;
}

.callout-right {
  margin: 18px 22px;
}

.callout .notch-left {
  top: 0px;
  left: 0;
  margin: 18px -10px;
  border-left: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #eef4f9;
}


.callout .notch-right {
  top: 0px;
  right: 0px;
  left: auto;
  margin: 18px -10px;
  border-right: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid antiquewhite;
}


.userMessageRow {
  width: 95%;
  display: flex;
  margin-top: 2px;
  margin-bottom: 0;
}

.userMessageContent {

  color: #212529;
  border-radius: 12px;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  background-color: eef4f9;
  margin-top: 0;
  margin-bottom: 0;
}

.supportMessageRow {
  width: 95%;
  display: flex;
  text-align: right;
  float: right;
}

.supportMessageContent {
  color: var(--button-secundary-bg-color);
  border-radius: 12px;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  background-color: antiquewhite;
  margin-top: 2px;
  margin-bottom: 0;
}

#notificationStatus {
  min-width: 50px;
}

.chatMessageDate {
  font-size: 0.8em;
  color: grey;
  text-align: right;
}

.popup .content,
.alert .content {
  background-color: #ffffff;
  width: fit-content;
  /* left: 5%; */
  position: relative;
  top: 5%;
  height: auto;
  border-radius: 4px;
  margin: 0 auto;
  padding: 10px;
}

.popup {
  width: 100%;
  height: 600px;
  background-color: rgb(0 0 0 / 25%); 
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.alert {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(0 0 0 / 72%);
  top: 0;
  left: 0;
  z-index: 10000;
}

#dropArea {
  border: 1px dashed black;
}

@media (max-width:600px) {
  .viewerFile {
    position: relative;
    overflow: hidden;
    min-height: 230px;
    margin-bottom: 10px;
    padding: 1%;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
  }
}

@media (min-width:601px) {
  .viewerFile {
    position: relative;
    overflow: hidden;
    min-height: 230px;
    margin-bottom: 15px;
    padding: 1%;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;

  }
}

.viewerFileDownload {
  float: left;
  margin-bottom: 15px;
  padding: 1%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}


.selectCompanies {
  max-width: 20em;
  width: auto;
}

/*
.react-checkbox-tree ol {
  list-style-type: none;
}

.rct-icon {
  color: #919191;
  position: relative;
  top: -12px;
  left: -4px;
}

.rct-icon-expand-close::before {
  content: "+";
  font-weight: bolder;
}

.rct-icon-expand-open::before {
  content: "-";
  font-weight: bolder;
  left: 2px;
  top: -3px;
  position: relative;
}

.rct-text button {
  
  background: transparent;
  padding: 6px;
  width: 15px;
  height: 15px;
  top: 8px;
  position: relative;
  border: 1px solid #919191;
  border-radius: 3px;
}

.rct-root-node input {
  display: none;
}

.rct-text input {
  width: auto;

}*/

.rct-family,
.rct-provider,
.rct-subprovider,
.rct-machine,
.rct-grant {
  font-weight: bold;
  display: block;
  border: solid 1px lightgrey;
  padding: 0 0.5em;
  margin-bottom: 0.5em;
  line-height: 2em;
}

.react-checkbox-tree {
  font-size: inherit !important;
}

.react-checkbox-tree .tree-visible {
  display: flex !important;
}

.react-checkbox-tree label:hover {
  background: #fff !important;
}


.nav-item .active {
  /*border-bottom: 4px solid var(--seconday-bg-color);*/
  background-color: lightgray;
  color: black
}

.weekDays input {
  width: auto;
}

.week-day {
  width: 13%;
}

.week-day label {
  width: 100%;
}

.navbar-alias {
  max-width: 20%;
  text-overflow: ellipsis;
  overflow: hidden;
}

#menu a {
  color: white;
  width: fit-content;
}

.submenu {
  padding-left: 10px;
  background-color: #404040;
  padding-right: 10px;
}


#menu>li {
  padding-top: 10px;
}

.logo-small {
  width: 70px;
}

.nav-pills .nav-link.active {
  background-color: initial;
  border-bottom: 1px solid white;
  border-radius: 0;
}

#menuContainer {
  background-color: var(--bg-color) !important;
}

#myTab button {
  color: black;
}

.qr-image {
  width: 200px;
  height: 200px;
}

.color-primary,
.color-primary .nav-link {
  color: var(--text-primary-color);
}

.color-secondary {
  color: var(--text-secondary-color);
}

.divider {
  border-left: 1px solid black;
}

.nav-link {
  cursor: pointer;
}

.hide_filter {
  display: none;
}

.show_filter {
  display: flex;
}

.max-width-400 {
  max-width: 400px;
}

.vertical-menu {
  max-width: 250px;
}

.promotion-card,
.tournament-card {
  margin: 0.5em;
  float: left;
  width: 100%;
  height: 100%;
  box-shadow: 3px 3px #9e9e9e7d;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
}

.promotion-card .card-header,
.tournament-card .card-header {
  min-height: 60px;
}

.add-promotion-image,
.add-tournament-image {
  width: 50%;
}

.tournament-title,
.promotion-title {
  font-weight: bolder;
  padding: 16px;
  display: block;
}

.promotions-list,
.tournaments-list {
  background-color: #00c19f1f;
  padding-bottom: 5em;
}

.promotions-list .card-header,
.tournaments-list .card-header {
  background-color: #00c19f1f;
}


.promotion-active,
.tournament-active {
  background-color: #8bc34a94;
}

.promotion-disabled,
.tournament-disabled {
  background-color: #e0e1df;
}

.promotion-expired,
.tournament-expired {
  background-color: #62725f85;
}

.tree-users {
  border: 1px solid #d1d1d1;
  padding: 1em;
}

.nav-link .active {
  background-color: #efefef !important;
  border-bottom: #efefef !important;
}


.nav-pills .nav-link.active {
  /*background-color: #212721  !important; */
  background-color: inherit;
  border-bottom: 1px solid white !important;
}

.report-table {
  min-height: 300px;
}

@media (max-width: 600px) {
  .report-table {
    min-height: 0px;
  }
}

.no-min-height .report-table {
  min-height: fit-content !important;
}


.MuiInputBase-input {
  border: none !important;
  padding: 16.5px 14px !important;
  padding-right: 0 !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-top: revert;
  margin-bottom: revert;
}

.MuiToolbar-root .MuiInputBase-input {
  padding: 6px 0 7px;
  box-sizing: inherit;
}

.MuiTablePagination-select.MuiInputBase-input {
  padding-right: 24px !important;

}




.MuiFormControl-root,
.MuiOutlinedInput-root {
  margin-bottom: 10px !important;
  width: 100%;
  min-height: 56px;

}


.loginInputs {
  background-color: #fcfcfc;
  padding: 20px 20px;
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 10px;
}

.showModal {
  display: block;
}

.hide {
  display: none;
}

.MuiButtonBase-root {
  width: auto;
}


.helpPanel {
  position: absolute;
  width: auto;
  z-index: 10000;
  background-color: #f2f2f2;
  color: black;
  left: 20%;
  top: 30%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1000px) {
  .helpPanel {
    position: absolute;
    width: 100%;
    left: 0;
    top: 5%;
  }

  .hideClock {
    display: none;
  }

  .hideUser {
    display: none;
  }
}

.helpHeader button {
  color: white;
  float: right;
}


.resizable {
  overflow: auto;
  resize: both;
  border: 1px solid black;
  min-width: 50px;
  min-height: 50px;

}

.hidden {
  display: none;
}

.visible {
  display: block;
}


.help_link {
  float: none !important;
  cursor: pointer;
  color: #b1aaaa;
}

.max_width_100 .card {
  max-width: 100%;
}

.profileTitle {
  line-height: 2.6em;
  font-size: 1.3em;
}

.profileTitleRow {
  background-color: var(--seconday-bg-color);
}

.profileHeader {
  background-color: #f2f2f2
}

.serverTime {
  line-height: 4em;
}

.profileMenu .nav-link {
  color: black;

}

.menu-icon {
  width: 24px;
  display: inline-block;
  text-align: center;
}

.container {
  max-width: none;
}

.table {
  color: #505050;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: silver;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.min-vh-75 {
  min-height: 75vh;
}

.promotionList {
  background-color: white;
  padding-bottom: 1em;
}

.min-vw-50 {
  min-width: 50vw;
}


/* draggable table */

.table-draggable {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.table-draggable th,
.table-draggable td {
  border: 1px solid #ccc;
}

.table-draggable th,
.table-draggable td {
  padding: 0.5rem;
}

.draggable {
  cursor: move;
  user-select: none;
}

.placeholder {
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
}

.clone-list {
  border-top: 1px solid #ccc;
}

.clone-table {
  border-collapse: collapse;
  border: none;
}

.clone-table th,
.clone-table td {
  border: 1px solid #ccc;
  border-top: none;
  padding: 0.5rem;
}

.dragging {
  background: #fff;
  border-top: 1px solid #ccc;
  z-index: 999;
}

.grid-machine-selected .lobbyMachine {
  background-color: #f44336;

}

.CalimacoEditorDiv {
  border: solid 1px;
  margin-bottom: 1em;
  border-color: rgba(0, 0, 0, 0.23);
  padding: 0.5em;
  border-radius: 4px;
  min-height: 50vh;
}

.CalimacoEditorDiv label {
  top: -14px;
  background-color: white;
  width: fit-content;
  font-size: 0.8em;
  padding: 0 0.4em;
}


.Border_Label {
  border: solid 1px;
  margin-bottom: 1em;
  border-color: rgba(0, 0, 0, 0.23);
  padding: 0.5em;
  border-radius: 4px;
}

#Marketing_MachinesTreeSelector-label {
  top: -14px;
  background-color: white;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.8em;
  padding: 0 0.4em;
}

.label-border-label {
  top: -19px;
  background-color: white;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.8em;
  padding: 0 0.4em !important;
}

.cms_image {

  max-width: -webkit-fill-available;
}

.calimaco_file {
  border: 1px solid black !important;
  width: 100%;
  padding: 1em;
}




.calimaco_table_red {
  border-radius: 2px;
  background-color: #f44336 !important;
  color: white;
  padding: 2px;
  height: max-content;
  text-align: center;
  white-space: nowrap;
}

.calimaco_table_green {
  border-radius: 2px;
  background-color: #4caf50 !important;
  color: white;
  padding: 2px;
  height: max-content;
  text-align: center;
  white-space: nowrap;
}

.calimaco_table_grey {
  border-radius: 2px;
  background-color: grey !important;
  color: white;
  padding: 2px;
  height: max-content;
  text-align: center;
  white-space: nowrap;

}

.calimaco_table_yellow {
  border-radius: 2px;
  background-color: #ffeb3b !important;
  color: #795548;
  padding: 2px;
  height: max-content;
  text-align: center;
}

#payout_promotions_redeemed,
#payout_low_odds,
#payout_account {
  position: absolute;
  min-width: 50%;
  background-color: #00c19f;
  padding: 7px;
  border-radius: 5px;

}

#user_files {

  min-width: 50%;
  background-color: #00c19f;
  padding: 7px;
  border-radius: 5px;

}

.table_layout_fixed {
  table-layout: fixed;
}

.user_files_total {
  cursor: pointer;
  background-color: #00c19f;
  width: fit-content;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 5px;
}

.no_data_found {
  text-align: center;
  color: red;
  font-weight: bolder;
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 2;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.center,
.center:hover,
.center:active,
.center:visited,
.center:focus {
  margin: 0 auto;
}

.tree_admin_selected {
  background-color: #00c19f96;
  border-radius: 6px;
  padding-left: 10px;
}

.tree_admin_unselected {
  padding-left: 10px;
  background-color: #ffffff;
}

.border {
  border: solid 1px grey;
  padding: 1em;
}

.calimaco_lobby_tag_item {
  padding: 0;
  margin: 0;
  max-height: 10px;
}

.calimaco_already_in_lobby {
  background-color: #00c19f0d;
}

.min-height-50 {
  min-height: 50vh;
}

.calimaco-border-arrow {
  border: solid 1px black;
  border-radius: 2px;
  margin: 8px;
  padding: 5px;
}

.calimaco-footer-totals {
  background-color: #f2f2f24f;
}


.calimaco-empty-arrow {
  margin-right: 34px;
  padding: 5px;
}

.calimaco-selected {
  background-color: #00c19f;
}

.calimaco_max_content {
  width: max-content;
}

.calimaco_logo {
  width: 120px;
}

.calimaco_img_left {
  /*  transform: rotate(-90deg) ; */
  writing-mode: horizontal-tb;
}

.calimaco_img_right {
  transform: rotate(+90deg);
}

.calimaco_img_down {
  transform: rotate(+180deg);
}

.calimaco_rotation-wrapper-outer {
  display: table;
}

.calimaco_rotation-wrapper-inner {
  padding: 50% 0;
  height: 0;
}

.calimaco_no_data_found {
  padding: 2em;
  border: solid 1px #c4c4c4;
  margin-top: 2em;
  margin-left: 2px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  color: red;
  font-weight: bold;
  flex-direction: column;
}

.calimaco_banner_preview {
  border: solid 1px;
  margin-bottom: 1em;
  border-color: rgba(0, 0, 0, 0.23);
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  width: calc(100% - 24px);


}

.calimaco_underline {
  text-decoration: underline;
  cursor: pointer;
}

.help_button {

  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  height: 3em;
}

iframe {
  display: block;
  /* iframes are inline by default */
  background: #fff;
  border: none;
  /* Reset default border */
  height: 100vh;
  /* Viewport-relative units */
  width: 80vw;
}

.data_user_selected {
  background-color: #00c19f75;
  margin: 0.5em;
  padding: 1em;
}

.ticket_print_container {
  margin: 1em;
  padding: 1em;

}

/* Pagina de apuestas para los camareros */

.calimaco_tickets_current_profile {
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 1em;
  background-color: #00c19f7d;
}

.calimaco_tickets_current_profile button {
  bottom: 1em;
  position: relative;
}

.calimaco_tickets_choose_profile {
  width: 95%;
  margin-top: 2em;
}

.calimaco_tickets_choose_profile .card-title {
  font-size: 1.2em;
  padding: 1em;
}

.calimaco_tickets_choose_profile .card-body {
  font-size: 1.2em;
}

.calimaco_tickets_choose_profile button,
.calimaco_tickets_choose_profile button:hover,
.calimaco_tickets_choose_profile button:visited,
.calimaco_tickets_choose_profile button:active {
  max-width: fit-content;
}


.validationField {
  color: rgb(243, 123, 123);
  font-size: small;
  margin-bottom: 15px;
  position: relative;
  padding-left: 15px;
}

.validationField.filled::before {
  content: '\f071';
  /* código del icono (puede ser diferente dependiendo de su conjunto de iconos) */
  font-family: 'Font Awesome 5 Free';
  /* nombre de la fuente de su conjunto de iconos */
  font-weight: 900;
  /* peso de la fuente (puede ser diferente dependiendo de su conjunto de iconos) */
  font-size: small;
}

.aliasHeaderOptions {
  color: white !important;
  font-size: medium;
  border: none;
  background-color: transparent;
}

.btn-primary:disabled {
  color: white;
  border-color: var(--button-primary-bg-color);
  background-color: var(--button-primary-bg-color);
  opacity: .4;
}

.borderGroupUsers {
  border: 1px solid rgb(222, 226, 230);
  padding: 5px;
}

.contenedorProgressBar {
  position: fixed;
  float: right;
  bottom: 0;
  margin-bottom: 30px;
  height: 53px;
  right: 0;
  margin-right: 10px;
  width: 50%;
  background-color: white;
  z-index: 51;
  border-radius: 5px;
  border: 1px solid black;
}

.progressBarText {
  text-align: center;
  display: grid;
}

.progressContainerResultOK {
  border: 1px solid rgb(222, 226, 230);
  border-radius: 4px;
  padding: 5px;
  color: green;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.progressContainerResultNOK {
  border: 1px solid rgb(222, 226, 230);
  border-radius: 4px;
  padding: 5px;
  color: red;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.CMSComponent {
  margin-bottom: 10px;
  margin-top: 10px;
}

.headerButton {
  padding-left: 4px;
  font-size: 1rem;
}

.calimaco-flow-diagram {
  position: relative;
}

.calimaco-flow-diagram-component {
  width: 100%;
  height: calc(100vh - 300px);
  position: relative;
  background-color: #59bea033;
  overflow: auto;
}

.calimaco-flow-status-component,
.calimaco-flow-status-react-component,
.calimaco-flow-status-react-action-component {
  width: 100%;
  height: calc(100vh - 300px);
  position: absolute;
  top: 0;
}


.calimaco-flow-status-react-action-component-bg,
.calimaco-flow-status-react-component-bg,
.calimaco-flow-status-component-bg {
  width: 100%;

  height: 100%;
  background-color: #212529db;
}

.calimaco-flow-status-react-action-component-data,
.calimaco-flow-status-react-component-data,
.calimaco-flow-status-component-data {
  margin: 0 auto;
  background: white;
  border-radius: 0.5em;
  position: absolute;
  border: 1px solid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;

}

.calimaco-flow-diagram-component>div {
  overflow: initial;
  position: initial;

}

.calimaco-flow-diagram-component .node {
  width: max-content;
}

.calimaco_float_left,
.calimaco_float_left:hover,
.calimaco_float_left:active,
.calimaco_float_left:visited,
.calimaco_float_left:focus {
  float: left !important;
}

.calimaco_float_right {
  float: right;
}

.calimaco-flow-status-component-data-header,
.calimaco-flow-status-react-component-data-header {
  line-height: 4em;
  text-align: center;
}

.calimaco-flow-status-component-data-content,
.calimaco-flow-status-react-component-data-content {
  margin: 1em;
}

.calimaco-fade-in {
  opacity: 0;
  transition: width 600ms, height 600ms, opacity 600ms;
  height: 0;
  width: 0;
  top: -1000vh;
}


.calimaco-fade-out {
  opacity: 1;
  transition: opacity 600ms;
  height: 100%;
  width: 100%;
  top: 0;
}

.pick .MuiInputBase-input {

  padding-left: 15% !important;
  padding-right: 15% !important;

}

.alertSize-none {
  width: auto;
  height: auto;
}

.alertSize-xl {
  width: 75vw;
  height: 75vh;
}

.alertSize-l {
  width: 60vw;
  height: 60vh;
}

.alertSize-m {
  width: 50vw;
  height: 50vh;
}

.alertSize-s2 {
  width: 35vw;
  height: 45vh;
}

.alertSize-s3 {
  width: 35vw;
  height: 40vh;
}

.alertSize-s {
  width: 35vw;
  height: 35vh;
}

.alertSize-xs {
  width: 25vw;
  height: 25vh;
}

.MuiChip-sizeSmall {
  font-size: 13px !important;
  background-color: rgba(0, 193, 159, 0.5) !important;
  margin-left: 10px !important;
  margin-top: 10px !important;
  border-radius: 4px !important;
}

.MuiChip-outlined .MuiChip-deleteIconSmall {
  color: grey !important;
}

.MuiButton-root {
  background-color: var(--seconday-bg-color) !important;
}

.MuiInputLabel-root.Mui-focused {
  color: var(--seconday-bg-color) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--seconday-bg-color) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: var(--seconday-bg-color) !important;

}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color:rgba(0, 0, 0, 0.87) !important;

}
.MuiAutocomplete-option:hover {
  background-color: rgba(0, 193, 159, 0.5) !important;
}

.checkbox-container {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.empty {
  margin-bottom: 10px;
  font-size: 25px;
  color: grey;
}

.tick {
  margin-bottom: 10px;
  font-size: 25px;
  color: #4caf50;
}

.cross {
  margin-bottom: 10px;
  font-size: 25px;
  color: #f44336;
}

.warning-textfield {
  border-color: var(--button-risk_medium-border-color);
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid var(--button-primary-bg-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.iframePlugin {
  width: 100%;
  height: 80vh;
}

/* Estilos personalizados para el editor JSON */
.jsoneditor {
  border: 2px solid #ebebeb !important;
  /* Cambia el borde del editor */
  border-radius: 5px;
  /* Cambia el radio de las esquinas del borde */
}

/* Estilos para la barra superior del editor */
.jsoneditor-menu {
  background-color: var(--seconday-bg-color) !important;
  /* Cambia el color de fondo de la barra */
  border-bottom: 1px solid var(--seconday-bg-color) !important;
  /* Cambia el borde inferior de la barra */
}

/* Estilos para la barra de estado del editor */
.jsoneditor-statusbar {
  background-color: #f0f0f0;
  /* Cambia el color de fondo de la barra de estado */
}

.jsoneditor-poweredBy {
  display: none !important;
}

.ace_variable {
  color: #59A5D8 !important;
}

.ace_string {
  color: #FA7921 !important;
}

.ace_gutter {
  z-index: 1 !important;
}

.jsoneditor-menu>button.jsoneditor-transform {
  background-position: -144px -96px;
  display: none !important;
}

.liveChatButton {
  background-color: #d75757;
  color: white !important;
  border: none;
  text-decoration: none !important;
  padding: 8px;
}

.liveChatButton a {
  text-decoration: none !important;
}

@media (max-width: 768px) {
  .mainNavbarButton {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100% !important;
  }

  .topBar .col-4.col-md-4 {
    height: 100%;
  }

  .topBar .col-4.col-md-4>div {
    position: relative;
    height: 100%;
  }

  .topBar .alias .float-end .btn-group {
    flex-direction: column;
  }

  .title {
    height: 100%;
    padding: 5px;
  }
}

.topBar .alias .float-end {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
}

div#connectionOptions {
  position: absolute;
  right: 40px;
  top: 40px;
}

.error-container {
  border: 2px solid red;
  padding: 10px;
  padding-bottom: 5px;
  margin-right: 56px;
  font-size: 15px;
}

.error-title {
  color: red;
  font-weight: bold;
}

.error-message {
  margin-left: 5px;
}

.hideUserBalance {
  display: none;
}

.table-cell {
  max-width: 300px;
  min-width: 20px;
  margin-right: 5px;
}

.icon-list-agents {
  display: flex;
  align-items: center;
}

.desktop-icons {
  display: flex;
  gap: 5px;
}
.desktop-icons-agent-control {
  display: block;
  gap: 5px;
}
.mobile-menu-agents-control {
  display: none;
}
.selectedAgents { 
  border: 1px solid #ccc;  
  overflow-y: auto;  
  padding: 1px;
  margin-bottom: 1em;
}
.selectedAgentsItem{

  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.mobile-menu {
  display: none;
}

.notificationHeaderOptions {
  font-size: medium;
  border: none;
  background-color: transparent;
}

.agents-currency {
  margin-left:0px;
}
.agents-alias-text {
  display: block;  
  white-space: nowrap;
  overflow: hidden;  
  font-size: 0.9em;
}



/* Media query para dispositivos móviles Agentes*/
@media (max-width: 768px) {
  .desktop-icons {
    display: none;
  }
  .desktop-icons-agent-control {
    display: none;    
  }
  .mobile-menu-agents-control {
    display: flex;
    gap: 0px;
    font-size: 0.9em;
    justify-content: space-between;
    align-items: center;
  }

.mobile-menu-agents-control .card-header {
    flex-grow: 1;
    text-align: left;
}

  /* .mobile-menu-agents-control .btn-primary {
    margin-top: 0;
    margin-bottom: auto;
  }
  .mobile-menu-agents-control .btn-secondary {
    margin-top: 0;
    margin-bottom: auto;
  } */
  

  .agents-currency {
    font-size: 0.9em;
    margin-left:3px;
  }

  .agents-alias {
    font-size: 0.9em;
  }

 
  .agents-alias-text {
    display: block;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
  }

  .icon {
    font-size: 0.7em;
    /* Ajusta el tamaño del icono según tus necesidades */
  }

  .icon-button {
    padding: 3px !important;
    /* Ajusta el padding para reducir el tamaño del botón si es necesario */
  }

  .mobile-menu {
    display: flex;
    gap: 0px;
    font-size: 0.9em;
  }
}

.List {
  border: 1px solid #d9dddd;
}

.RowEven,
.RowOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RowEven {
  background-color: #f8f8f0;
}

.List {
  border: 1px solid rgba(0,0,0,.125);
  font-size:0.9em;
}

.tree-item-open,
.tree-item-closed {
  display: flex;
  align-items: center;
  justify-content: left;
}

.tree-branch::after { 
  display: block;
  width: 0;
  height: 0;
  margin-top: 1px;
  margin-left: 23px;
  border-top: 6px solid rgba(0, 0, 0, 0.7);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: -36px;
  /*  transform: rotate(90deg);
  animation-duration: 0.3s;
  transition: border 0.3s;
  transform: translate(0, 0);*/
}

.tree-item-closed::after {
  border-left: 6px solid rgba(0, 0, 0, 0.7);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  /* transform: rotate(90deg); 
  animation-duration: 0.3s;
  transform: translate(2px, -2px);
  transition: border 0.3s;*/
}

.item-background {
  position: 'absolute';
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 35;
  border-bottom:1px solid rgba(0, 0, 0, .125);
}

.item-background:hover {
  background-color: rgba(207, 208, 209, 1);
  cursor: pointer;
}
.tree-item-closed .agents-currency {
  margin-left:5px;
}
.marquee {  
  line-height: 2em; 
  background-color: red;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  
}
.marquee_alert{ background-color: red;}
.marquee_info { background-color: blue;}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 35s linear infinite;
  max-width: none;
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}