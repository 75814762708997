.react-flow__node-statusNode {
  font-size: 10px;
  width: 180px;  
  color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  border-radius: 2px;
}

.react-flow__node-statusNode .react-flow__handle {
  /* top: 24px; */
  right: -15px;
  width: 6px;
  height: 10px;
  border-radius: 2px;
  background-color: #778899;
}

.react-flow__node.circle {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.react-flow__node.annotation {
  border-radius: 0;
  text-align: left;
  background: white;
  border: none;
  line-height: 1.4;
  width: 225px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
}

.react-flow__node.annotation .react-flow__handle {
  display: none;
}

.statusNode-node__header {
  padding: 8px 10px;
  border-bottom: 1px solid #e2e8f0;
  background: rgba(0, 0, 0, 0.3);
}

.statusNode-node__body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  padding: 10px;
}

.statusNode-node__select {
  position: relative;
  margin-bottom: 10px;
}

.statusNode-node__select span {
  width: 100%;
  margin-top: 5px;
  font-size: 10px;
}
.reactAction_item {
  /* text-align: center; */
  margin: 7px;
  background-color: #e9ecef;
  padding: 4px;
  border: 1px solid #999999;
}
.reactAction_addItem {
  display: inline-block;
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 5px;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}